import React, { useEffect, useState } from "react";
import { getTradingPairs } from "service/scanner";

export default function useTradingPair() {
  const [pairs, setPairs] = useState(null);

  useEffect(() => {
    getTradingPairs().then((data) => {
      setPairs(
        data?.data?.results?.map((pairs) => {
          return pairs?.symbol;
        })
      );
    });
  }, []);
  return { pairs };
}
