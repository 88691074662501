import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Order from "./order/order";
// import useResize from "../hook/resize-hooks";
// import Search from "./cryto/crypto";
// import DatePicker from "./date-picker/date-picler";
// import Filter from "./filter/index";

// import ProfileDialog from ".."
//congratulationWithStar dialog
const styles = {
  fullList: {
    width: "100vw",
  },
};

const ModalDialog = (props) => {
  const [Component, setComponent] = useState(null);
  // const [reff, getHeight] = useResize();
  let DrawerContent = null;
  let { classes, anchor } = props;

  // let drawerInnerContent = () => {
  //   switch (props.type) {
  //     case "filter":
  //       return (
  //         <Filter
  //           {...props.drawerData}
  //           handlerDialog={props.handlerDialog}
  //           onClose={props.handleClose}
  //         ></Filter>
  //       );
  //     case "search":
  //       return (
  //         <Search
  //           {...props.drawerData}
  //           handlerDialog={props.handlerDialog}
  //           onClose={props.handleClose}
  //         ></Search>
  //       );
  //     case "datePicker":
  //       return (
  //         <DatePicker
  //           {...props.drawerData}
  //           handlerDialog={props.handlerDialog}
  //           onClose={props.handleClose}
  //         ></DatePicker>
  //       );
  //     default:
  //       return <div>Empty</div>;
  //   }
  // };

  let drawerContent = () => {
    switch (props.type) {
      case "order":
        return (
          <Order
            {...props.drawerData}
            handlerDialog={props.handlerDialog}
            onClose={props.handleClose}
          ></Order>
        );
        break;
      default:
        return <div>empty</div>;
    }
  };

  return (
    <React.Fragment key={anchor}>
      <Drawer
        // ref={reff}
        anchor={"right"}
        className={`sm:w-full w-1/3 wrap`}
        PaperProps={{
          className: "sm:w-full w-1/3",
        }}
        open={props.open}
        onClose={props.handleClose}
      >
        <div
          className={`w-full h-full   ${props.drawerData.wrapperClass || ""}`}
        >
          {drawerContent()}
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default withStyles(styles)(ModalDialog);
