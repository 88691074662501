import ReactECharts from "echarts-for-react";
import React, { useCallback } from "react";
import moment from "moment";
const HeatChart = (props) => {
  const hours = props.xaxis?.map((date) => {
    return moment.utc(date).local().format("lll");
  });

  // {moment(data.order_update_date).format("lll")}
  // prettier-ignore

  const days = props.yaxis?.map((y) => {
    return y.name;
  });
  const data = useCallback(() => {
    const chartData = [];
    props.yaxis.map((y, index) => {
      return y.data?.map((data, j) => {
        chartData.push([j, index, 0, data]);
      });
    });

    return chartData;
  }, [props.yaxis]);

  const options = {
    // tooltip: {
    //   position: "top",
    // },
    grid: {
      left: "100px",
      top: "50px",
      bottom: "80px",
      right: 0,
    },
    xAxis: {
      type: "category",
      axisLabel: {
        interval: props.xaxis?.length >= 20 ? 2 : 1,
        rotate: 30, //If the label names are too long you can manage this by rotating the label.
      },
      data: hours,
      splitArea: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      data: days,
      axisLabel: {
        interval: 0,
      },
      splitArea: {
        show: false,
      },
    },
    visualMap: {
      orient: "horizontal",
      left: "center",
      top: "0",
      pieces: props.ranges?.map((color) => {
        return {
          min: color.from,
          max: color.to,
          color: color.color,
        };
      }),
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      formatter: function (params) {
        return (
          "X: " + props.yaxis[params.data[1]]?.name + "<br>Y: " + params.name
        );
      },
    },
    series: [
      {
        progressive: 0,
        animation: false,
        type: "heatmap",
        data: data(),
        // label: {
        //   normal: {
        //     show: true,
        //     formatter: (param) => {
        //       return "0";
        //     },
        //   },
        // },
      },
    ],
  };

  return (
    <div>
      <ReactECharts
        style={{
          height: props.yaxis.length * 12 + 150,
          width: props.xaxis?.length >= 72 ? props.xaxis?.length * 12 : "100%",
        }}
        opts={{
          renderer: "canvas",
          devicePixelRatio: "1",
          height:
            props.yaxis?.length >= 72
              ? props.yaxis.length * 12 + 150
              : props.yaxis?.length * 12 + 150,
          width: props.xaxis?.length >= 72 ? props.xaxis?.length * 12 : "auto",
        }}
        option={options}
      />
    </div>
  );
};

export default HeatChart;
