import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
const LoaderWrapper = (props) => {
  const { loader, minWidthSet = true, loaderClass } = props;
  return (
    <div
      className={`position-relative ${
        loader && minWidthSet && ""
      } ${loaderClass}`}
    >
      {loader && (
        <div className="loader-overlay">
          <CircularProgress className="svg primary" />
        </div>
      )}
      {loader ? <div style={{ minHeight: "300px" }}></div> : props.children}
    </div>
  );
};

export default LoaderWrapper;
