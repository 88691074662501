/*eslint-disable*/
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import classNames from "classnames";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// import { SideBarLink } from "../../routes";
import Collapse from "@material-ui/core/Collapse";
import { Container } from "@material-ui/core";
import { isAdmin } from "utils/helper";
import { getAuthData } from "utils/helper";
import { Icons } from "routes";
import { TrendingUpOutlined } from "@material-ui/icons";
const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  const [active, setActiveRoute] = useState();

  const admin = isAdmin();

  useEffect(() => {
    if (window.location.href) {
      const layout = window.location.href
        ?.replace("https://", "")
        ?.replace("http://", "")
        ?.split("/");

      setActiveRoute(layout?.[2]);
    }
  }, [window.location.href]);

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href?.split("/")?.indexOf(routeName) > -1
      ? true
      : false;
  }

  const { color, logo, image, logoText, routes } = props;
  const SideBarLink = getAuthData().sideMenu || [];
  // return "";
  var links = (
    <List className={classes.list}>
      {SideBarLink.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        const isPathActve = activeRoute(prop.id);
        listItemClasses = classNames({
          [" " + classes[color]]: isPathActve,
        });

        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: isPathActve,
        });

        const Icon = Icons[prop?.id]?.icon;
        return true ? (
          prop?.children ? (
            <div className={active == prop.id ? "nav-active  mx-3" : ""}>
              <ListItem
                button
                onClick={() => {
                  setActiveRoute(active != prop.id ? prop.id : "");
                }}
                style={{
                  transition: "none",
                }}
                className={
                  (active == prop.id ? "px-0 " : "") + "  " + classes.itemLink
                }
              >
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(
                      classes.itemIcon,
                      whiteFontClasses,
                      {}
                    )}
                  >
                    {/* {prop.icon} */}
                  </Icon>
                ) : (
                  <Icon
                    className={classNames(
                      classes.itemIcon,
                      whiteFontClasses,
                      {}
                    )}
                  />
                )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {})}
                  disableTypography={true}
                />
              </ListItem>
              {active == prop.id && (
                <Collapse in={active == prop.id} timeout="auto" unmountOnExit>
                  <Container
                    button
                    className={
                      classes.itemLink +
                      listItemClasses +
                      "px-0 d-flex flex-column py-0 px-0 mx-0 my-0 "
                    }
                  >
                    {prop?.children?.map((item) => {
                      const isActive = activeRoute(item?.id);
                      listItemClasses = classNames({
                        [" " + classes[color]]: isActive,
                      });
                      return admin || item.auth == "user" ? (
                        <NavLink
                          to={item.layout + item.path}
                          className={"w-100 px-0 mx-0"}
                          key={key}
                        >
                          <ListItem
                            className={
                              classes.itemLink + listItemClasses + " mx-0  "
                            }
                            sx={{ pl: 4 }}
                          >
                            <ListItemText
                              primary={
                                props.rtlActive ? prop.rtlName : item.name
                              }
                              className={classNames(
                                classes.itemText,
                                whiteFontClasses,
                                {
                                  [classes.itemTextRTL]: props.rtlActive,
                                }
                              )}
                              disableTypography={true}
                            />
                          </ListItem>
                        </NavLink>
                      ) : (
                        ""
                      );
                    })}
                  </Container>
                </Collapse>
              )}
            </div>
          ) : (
            <NavLink
              to={prop.layout + prop.path}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem
                button
                className={classes.itemLink + listItemClasses + "  d-flex"}
              >
                {typeof Icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          )
        ) : (
          ""
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <div
        href="#"
        className={classNames(classes.logoLink + " mt-1", {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        target="_blank"
      >
        {/* <div className={classes.logoImage}> */}
        {/* <img src={logo} alt="logo" className={classes.img} /> */}
        {/* </div> */}
        {logoText}
      </div>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
