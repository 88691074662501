import "assets/css/custom.css";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import { createBrowserHistory } from "history";
import DialogHoc from "hoc/dialog";
import DrawerContext from "hoc/drawerHoc";
// core components
import Admin from "layouts/Admin.js";
import React from "react";
import ReactDOM from "react-dom";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { isAdmin } from "utils/helper";
import { getFirstPath } from "utils/helper";
import { getAuthData } from "utils/helper";
import Login from "views/login/login";

const hist = createBrowserHistory();

ReactDOM.render(
  <div>
    <DialogHoc></DialogHoc>
    <DrawerContext></DrawerContext>
    <Router history={hist}>
      <Switch>
        {getAuthData().auth ? (
          <Route path="/admin" component={Admin} />
        ) : (
          <Route path="/" exact={true} component={Login} />
        )}

        {getAuthData().auth ? (
          <Redirect from="/" to={getFirstPath()} />
        ) : (
          <Redirect path="/" to="/" />
        )}
      </Switch>
    </Router>
  </div>,
  document.getElementById("root")
);
