import React, { useState } from "react";
import { getTrads } from "service/trads";

const useTrad = () => {
  const [trads, setTrads] = useState(null);
  const [loader, setLoader] = useState(false);
  const getTradsData = async (id) => {
    setLoader(true);
    const data = await getTrads(id);
    setTrads(data?.data);
    setLoader(false);
  };
  return { trads, loader, getTradsData };
};
export default useTrad;
