import axios from "axios";
import { getAuthData } from "utils/helper";
import { BASE_URL } from "../variables/config";

const authData = getAuthData();

const request = {
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
};

if (authData.auth) {
  request["headers"]["authorization"] = "Token " + authData.token;
}
const axiosInstance = axios.create(request);

axiosInstance.interceptors.request.use((request) => {
  return request;
});

export default axiosInstance;
