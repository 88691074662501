const { ALERT_LOGS, ALERT_LOGS_DATA } = require("./endpoint");
const { get } = require("./service-methods");

export const getAlertsLogs = (
  id,
  timeframe,
  startDate,
  endDate,
  flag,
  sub_alert_id
) => {
  let appendUrl = "";
  if (flag) {
    appendUrl = `&flag=${flag}&sub_alert_id=${sub_alert_id}`;
  }
  return get(
    ALERT_LOGS +
      `?id=${id}&timeframe=${timeframe}&start=${startDate}&end=${endDate}${appendUrl}`
  );
};

export const getAlertLogsData = (
  id,
  timeframe,
  startDate,
  endDate,
  flag,
  sub_alert_id
) => {
  let appendUrl = "";
  if (flag) {
    appendUrl = `&alert_id=${sub_alert_id}`;
  }
  return get(
    ALERT_LOGS_DATA +
      `?aggregator_id=${id}&start=${startDate}&end=${endDate}${appendUrl}`
  );
};
