import DateFnsUtils from "@date-io/date-fns";
import { lime } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect } from "react";

export default function DatePicker(props) {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(props.date);

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: lime,
    },
  });

  useEffect(() => {
    props.date && setSelectedDate(props.date);
  }, [props.date]);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.onDateSelect && props.onDateSelect(date);
  };

  return (
    <MuiPickersUtilsProvider theme={defaultMaterialTheme} utils={DateFnsUtils}>
      <KeyboardDatePicker
        PopoverProps={{
          transitionDuration: {
            appear: 0,
            enter: 0,
            exit: 0,
          },
        }}
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        id="date-picker-inline"
        label={props.label || ""}
        value={selectedDate}
        onChange={handleDateChange}
        autoOk={true}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
