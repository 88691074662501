import moment from "moment";
import { useState } from "react";
import { getAlertLogsData, getAlertsLogs } from "../../service/alert-logs";
import { convertDateToUtc, getHourMinuts } from "../../utils/helper";
import orderBy from "lodash.orderby";
const useAlertsLogs = (config = {}) => {
  const [alertsLogs, setalertsLogs] = useState(null);
  const [loader, setLoader] = useState(false);
  const [logs, setLogs] = useState(null);

  const getAlertLogs = async (config = {}) => {
    setLoader(true);
    const { id, timefram = "h", date = "", cb, flag, sub_alert_id } = config;

    let startDate = "";
    let endDate = "";
    if (timefram == "h") {
      startDate = convertDateToUtc(moment(date).startOf("day")).unix();
      endDate = convertDateToUtc(moment(date).endOf("day")).unix();
    } else {
      startDate = moment(date).unix();
      endDate = moment(date).add(1, "hours").subtract(1, "second").unix();
    }

    try {
      const alertLogs = await getAlertsLogs(
        id,
        timefram,
        startDate,
        endDate,
        flag,
        sub_alert_id
      );

      const logs = alertLogs.data.data;
      let subAlert = alertLogs.data["sub-alerts"];
      let xaxis = [];
      let yaxis = [];
      let newSubAlert = {};
      Object.keys(subAlert).map((key) => {
        newSubAlert[key] = { ...subAlert[key], alert: key };
      });

      orderBy(Object.values(newSubAlert), ["is_active"], ["desc"]).map(
        (key) => {
          xaxis = [];
          yaxis.push({
            name: key.alert,
            data: logs[key.alert].map((data) => {
              xaxis.push(getHourMinuts(data[0]));
              return typeof data[1] != "undefined" ? data[1] : 0;
            }),
          });
        }
      );
      setalertsLogs({
        yaxis: yaxis.reverse(),
        xaxis: xaxis,
        data: Object.values(logs),
        subAlert: subAlert,
      });
      setLoader(false);
      cb && cb({});
    } catch (e) {
      cb && cb(e.response);
      setLoader(false);
    }
  };

  const getLogs = async (config = {}) => {
    setLoader(true);
    const { id, timefram = "h", date = "", cb, flag, sub_alert_id } = config;

    let startDate = "";
    let endDate = "";
    if (timefram == "h") {
      startDate = convertDateToUtc(moment(date).startOf("day")).unix();
      endDate = convertDateToUtc(moment(date).endOf("day")).unix();
    } else {
      startDate = moment(date).unix();
      endDate = moment(date).add(1, "hours").subtract(1, "second").unix();
    }

    try {
      const alertLogs = await getAlertLogsData(
        id,
        timefram,
        startDate,
        endDate,
        flag,
        sub_alert_id
      );

      const logs = alertLogs.data;

      setLogs(logs);
      setLoader(false);
      cb && cb({});
    } catch (e) {
      cb && cb(e.response);
      setLoader(false);
    }
  };

  return {
    getAlertLogs,
    alertsLogs,
    setalertsLogs,
    loader,
    logs,
    setLoader,
    setLogs,
    getLogs,
  };
};

export default useAlertsLogs;
