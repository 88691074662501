import { getAuthData } from "utils/helper";
import axiosInstance from "./axios";
const authData = getAuthData();
export async function get(endPoint, config = {}) {
  endPoint = `${endPoint}${endPoint.includes("?") ? "&" : "?"}user_id=${
    authData.user_id
  }`;
  return axiosInstance.get(endPoint, config);
}

export async function deleteReq(endPoint, config = {}) {
  endPoint = `${endPoint}${endPoint.includes("?") ? "&" : "?"}user_id=${
    authData.user_id
  }`;
  return axiosInstance.delete(endPoint, config);
}

export async function patch(endPoint, data = {}, config = {}) {
  if (Object.keys.length > 0) {
    endPoint = `${endPoint}${endPoint.includes("?") ? "&" : "?"}user_id=${
      authData?.user_id
    }`;

    data["user"] = authData?.user_id;
  }
  return axiosInstance.patch(endPoint, data, config);
}
export async function post(endPoint, data = {}, config = {}) {
  if (Object.keys.length > 0) {
    data["user"] = authData.user_id;
  }
  return axiosInstance.post(endPoint, data, config);
}
