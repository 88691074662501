import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { GraphicEq, TableChart } from "@material-ui/icons";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import AutoGraphIcon from "@material-ui/icons/Autorenew";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Notifications from "@material-ui/icons/Notifications";
import DocumentScannerIcon from "@material-ui/icons/Scanner";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { setStorage } from "utils/helper";
import Group from "@material-ui/icons/Group";

import ErrorBox from "../../components/erroBox/errorBox";
import LoaderWrapper from "../../hoc/loaderHoc";
import { loginService } from "../../service/auth";
import { getFirstPath } from "utils/helper";

const DiscordIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // xmlns:svgjs="http://svgjs.com/svgjs"
      version="1.1"
      width={20}
      height={20}
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{ enableBackground: "new 0 0 512 512", marginRight: "15px" }}
      xmlSpace="preserve"
    >
      <g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m3.58 21.196h14.259l-.681-2.205 1.629 1.398 1.493 1.338 2.72 2.273v-21.525c-.068-1.338-1.22-2.475-2.648-2.475l-16.767.003c-1.427 0-2.585 1.139-2.585 2.477v16.24c0 1.411 1.156 2.476 2.58 2.476zm10.548-15.513-.033.012.012-.012zm-7.631 1.269c1.833-1.334 3.532-1.27 3.532-1.27l.137.135c-2.243.535-3.26 1.537-3.26 1.537s.272-.133.747-.336c3.021-1.188 6.32-1.102 9.374.402 0 0-1.019-.937-3.124-1.537l.186-.183c.291.001 1.831.055 3.479 1.26 0 0 1.844 3.15 1.844 7.02-.061-.074-1.144 1.666-3.931 1.726 0 0-.472-.534-.808-1 1.63-.468 2.24-1.404 2.24-1.404-.535.337-1.023.537-1.419.737-.609.268-1.219.4-1.828.535-2.884.468-4.503-.315-6.033-.936l-.523-.266s.609.936 2.174 1.404c-.411.469-.818 1.002-.818 1.002-2.786-.066-3.802-1.806-3.802-1.806 0-3.876 1.833-7.02 1.833-7.02z"
          fill="#ffffff"
          data-original="#000000"
          style={{}}
          className
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m14.308 12.771c.711 0 1.29-.6 1.29-1.34 0-.735-.576-1.335-1.29-1.335v.003c-.708 0-1.288.598-1.29 1.338 0 .734.579 1.334 1.29 1.334z"
          fill="#ffffff"
          data-original="#000000"
          style={{}}
          className
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m9.69 12.771c.711 0 1.29-.6 1.29-1.34 0-.735-.575-1.335-1.286-1.335l-.004.003c-.711 0-1.29.598-1.29 1.338 0 .734.579 1.334 1.29 1.334z"
          fill="#ffffff"
          data-original="#000000"
          style={{}}
          className
        />
      </g>
    </svg>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

export default function Login() {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState("");
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const handleForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const requestPayload = {
      username: formData.username,
      password: formData.password,
    };
    setLoader(true);

    loginService(requestPayload)
      .then((data) => {
        setLoader(false);
        setError(null);
        const permissions = data?.data?.permissions;

        const menu = [
          {
            name: "Dashboards",
            icon: TableChart,
            layout: "/admin",
            path: "/dashbord",
            id: "dashbord",
            permissions: {
              view: permissions?.includes("api.menu_dashboard"),
              delete: permissions?.includes("api.delete_dashboard"),
              addUpdate: permissions?.includes("api.add_dashboard"),
            },
            // children: [
            //   {
            //     path: "/dashbord/163-v3",
            //     layout: "/admin",
            //     name: "163 V3(1h-50h)",
            //     id: "163-v3",
            //     permissions: {
            //       view: permissions?.includes("api.menu_dashboard"),
            //       delete: permissions?.includes("api.delete_dashboard"),
            //       addUpdate: permissions?.includes("api.add_dashboard"),
            //     },
            //   },
            //   {
            //     path: "/dashbord/163-v5",
            //     layout: "/admin",
            //     name: "163 V5(1h-50h)",
            //     id: "163-v5",
            //     permissions: {
            //       view: permissions?.includes("api.menu_dashboard"),
            //       delete: permissions?.includes("api.delete_dashboard"),
            //       addUpdate: permissions?.includes("api.add_dashboard"),
            //     },
            //   },
            //   {
            //     path: "/dashbord/163-v3-2",
            //     layout: "/admin",
            //     name: "163 V3 2(1h-23h)",
            //     id: "163-v3-2",
            //     permissions: {
            //       view: permissions?.includes("api.menu_dashboard"),
            //       delete: permissions?.includes("api.delete_dashboard"),
            //       addUpdate: permissions?.includes("api.add_dashboard"),
            //     },
            //   },
            //   {
            //     path: "/dashbord/163-v5-2",
            //     layout: "/admin",
            //     name: "163 V5 2(1h-23h)",
            //     id: "163-v5-2",
            //     permissions: {
            //       view: permissions?.includes("api.menu_dashboard"),
            //       delete: permissions?.includes("api.delete_dashboard"),
            //       addUpdate: permissions?.includes("api.add_dashboard"),
            //     },
            //   },
            //   {
            //     path: "/dashbord/163-v5-3",
            //     layout: "/admin",
            //     name: "163 v5(1d-10d)",
            //     id: "163-v5-3",
            //     permissions: {
            //       view: permissions?.includes("api.menu_dashboard"),
            //       delete: permissions?.includes("api.delete_dashboard"),
            //       addUpdate: permissions?.includes("api.add_dashboard"),
            //     },
            //   },
            //   {
            //     path: "/dashbord/163-v5-4",
            //     layout: "/admin",
            //     name: "163 v5(1d-30d)",
            //     id: "163-v5-4",
            //     permissions: {
            //       view: permissions?.includes("api.menu_dashboard"),
            //       delete: permissions?.includes("api.delete_dashboard"),
            //       addUpdate: permissions?.includes("api.add_dashboard"),
            //     },
            //   },
            //   {
            //     path: "/dashbord/163-v5-5",
            //     layout: "/admin",
            //     name: "163 v5(1d-100d)",
            //     id: "163-v5-5",
            //     permissions: {
            //       view: permissions?.includes("api.menu_dashboard"),
            //       delete: permissions?.includes("api.delete_dashboard"),
            //       addUpdate: permissions?.includes("api.add_dashboard"),
            //     },
            //   },
            // ],
          },

          {
            path: "/backtester",
            name: "Backtester",
            icon: Notifications,
            id: "backtester",
            layout: "/admin",
            permissions: {
              view: permissions?.includes("api.menu_autostrategy"),
              delete: permissions?.includes("api.delete_autostrategy"),
              addUpdate: permissions?.includes("api.add_autostrategy"),
            },
          },
          {
            path: "/alert-list",
            name: "Alerts",
            icon: Notifications,
            id: "alert-list",
            layout: "/admin",
            permissions: {
              view: permissions?.includes("api.menu_strategy"),
              delete: permissions?.includes("api.delete_strategy"),
              addUpdate: permissions?.includes("api.add_strategy"),
            },
          },
          {
            path: "/auto-strategy-list",
            name: "Auto Strategies",
            icon: Notifications,
            id: "auto-strategy-list",
            layout: "/admin",
            permissions: {
              view: permissions?.includes("api.menu_autostrategy"),
              delete: permissions?.includes("api.delete_autostrategy"),
              addUpdate: permissions?.includes("api.add_autostrategy"),
            },
          },
          {
            path: "/auto-scanner",
            name: "Auto Scanners",
            icon: AutoGraphIcon,
            layout: "/admin",
            id: "auto-scanner",
            auth: "user",
            permissions: {
              view: permissions?.includes("api.menu_scanner"),
              delete: permissions?.includes("api.delete_scanner"),
              addUpdate: permissions?.includes("api.add_scanner"),
            },
          },
          {
            path: "/accounts",
            name: "Accounts",
            icon: SupervisorAccountIcon,
            layout: "/admin",
            id: "accounts",
            permissions: {
              view: permissions?.includes("api.menu_exchange"),
              delete: permissions?.includes("api.delete_exchange"),
              addUpdate: permissions?.includes("api.add_exchange"),
            },
          },
          {
            path: "/discord-chanels",
            name: "Discord Channels",
            icon: DiscordIcons,
            layout: "/admin",
            id: "discord-chanels",
            permissions: {
              view: permissions?.includes("api.menu_discord"),
              delete: permissions?.includes("api.delete_discord"),
              addUpdate: permissions?.includes("api.add_discord"),
            },
          },
          {
            name: "Scanners",
            icon: DocumentScannerIcon,
            layout: "/admin",
            id: "scanners",
            permissions: {
              view: permissions?.includes("api.menu_scanner"),
              delete: permissions?.includes("api.delete_scanner"),
              addUpdate: permissions?.includes("api.add_scanner"),
            },
            children: [
              {
                path: "/scanners/62-condition",
                layout: "/admin",
                name: "62 Condition",
                id: "62-condition",
                permissions: {
                  view: permissions?.includes("api.menu_scanner"),
                  delete: permissions?.includes("api.delete_scanner"),
                  addUpdate: permissions?.includes("api.add_scanner"),
                },
              },
              {
                path: "/scanners/101-condition",
                layout: "/admin",
                name: "101 Condition",
                id: "101-condition",
                permissions: {
                  view: permissions?.includes("api.menu_scanner"),
                  delete: permissions?.includes("api.delete_scanner"),
                  addUpdate: permissions?.includes("api.add_scanner"),
                },
              },
              {
                path: "/scanners/163-condition",
                layout: "/admin",
                name: "163 Condition",
                id: "163-condition",
                permissions: {
                  view: permissions?.includes("api.menu_scanner"),
                  delete: permissions?.includes("api.delete_scanner"),
                  addUpdate: permissions?.includes("api.add_scanner"),
                },
              },
              {
                path: "/scanners/35-condition",
                layout: "/admin",
                name: "35 Condition",
                id: "35-condition",
                permissions: {
                  view: permissions?.includes("api.menu_scanner"),
                  delete: permissions?.includes("api.delete_scanner"),
                  addUpdate: permissions?.includes("api.add_scanner"),
                },
              },
              {
                path: "/scanners/camp10-condition",
                layout: "/admin",
                name: "Camp 10 Condition",
                id: "camp10-condition",
                permissions: {
                  view: permissions?.includes("api.menu_scanner"),
                  delete: permissions?.includes("api.delete_scanner"),
                  addUpdate: permissions?.includes("api.add_scanner"),
                },
              },
              {
                path: "/scanners/62-101-condition",
                layout: "/admin",
                name: "62 & 101 Condition",
                id: "62-101-condition",
                permissions: {
                  view: permissions?.includes("api.menu_scanner"),
                  delete: permissions?.includes("api.delete_scanner"),
                  addUpdate: permissions?.includes("api.add_scanner"),
                },
              },
              {
                path: "/scanners/62-101-163-condition",
                layout: "/admin",
                name: "62 & 101 & 163 Condition",
                id: "62-101-163-condition",
                permissions: {
                  view: permissions?.includes("api.menu_scanner"),
                  delete: permissions?.includes("api.delete_scanner"),
                  addUpdate: permissions?.includes("api.add_scanner"),
                },
              },

              {
                path: "/scanners/scanner-chain",
                layout: "/admin",
                name: "Scanner Chain",
                id: "scanner-chain",
                permissions: {
                  view: permissions?.includes("api.menu_scanner"),
                  delete: permissions?.includes("api.delete_scanner"),
                  addUpdate: permissions?.includes("api.add_scanner"),
                },
              },
            ],
          },
          {
            name: "Users",
            icon: Group,
            layout: "/admin",
            id: "users",
            permissions: {
              view: permissions?.includes("auth.view_user"),
              delete: permissions?.includes("auth.delete_user"),
              addUpdate: permissions?.includes("auth.add_user"),
            },
            children: [
              {
                path: "/users/user",
                layout: "/admin",
                name: "Create User",
                id: "user",
                permissions: {
                  view: permissions?.includes("auth.view_user"),
                  delete: permissions?.includes("auth.delete_user"),
                  addUpdate: permissions?.includes("auth.add_user"),
                },
              },
              {
                path: "/users/user-list",
                layout: "/admin",
                name: "User List",
                id: "user-list",
                permissions: {
                  view: permissions?.includes("auth.view_user"),
                  delete: permissions?.includes("auth.delete_user"),
                  addUpdate: permissions?.includes("auth.add_user"),
                },
              },
            ],
          },
          {
            path: "/trading-pair",
            name: "Trading Pairs",
            icon: GraphicEq,
            layout: "/admin",
            id: "trading-pair",
            permissions: {
              view: permissions?.includes("api.menu_scanner"),
              delete: permissions?.includes("api.delete_scanner"),
              addUpdate: permissions?.includes("api.add_scanner"),
            },
          },
        ];

        setStorage(
          "root",
          JSON.stringify({
            auth: true,
            ...data.data,
            sideMenu: menu?.filter((item) => {
              return item?.permissions?.view;
            }),
          })
        );
        window.location.href = getFirstPath();
      })
      .catch((e) => {
        setLoader(false);
        setError(e.response.data);
      });
  };

  return (
    <Container className="login-continer">
      <Container className="m-auto" maxWidth="xs">
        <CssBaseline />

        <Card className="mt-0 mb-4">
          <LoaderWrapper loader={loader}>
            <CardBody>
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Login
                </Typography>
                <form className={classes.form} noValidate>
                  <CustomInput
                    labelText="Username"
                    id="user_name"
                    inputProps={{
                      value: formData.username,
                      name: "username",
                      onChange: handleForm,
                    }}
                    formControlProps={{
                      fullWidth: true,
                      className: "mt-2",
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="paswsword"
                    inputProps={{
                      value: formData.password,
                      name: "password",
                      type: "password",
                      onChange: handleForm,
                    }}
                    formControlProps={{
                      fullWidth: true,
                      className: "mt-3",
                    }}
                  />
                  {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          /> */}

                  <Button
                    type="submit"
                    disabled={!formData.username || !formData.password}
                    color="primary"
                    className="w-100 mt-4 handleForm"
                    onClick={onSubmit}
                  >
                    Login
                  </Button>
                </form>
              </div>
            </CardBody>{" "}
          </LoaderWrapper>{" "}
          {error && <ErrorBox errors={error}> </ErrorBox>}
        </Card>
      </Container>
    </Container>
  );
}
