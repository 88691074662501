import { Button, Typography } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
// import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import Table from "components/Table/Table.js";

import GridItem from "components/Grid/GridItem.js";
import LoaderWrapper from "hoc/loaderHoc";
// import Table from "components/Table/Table.js";
// import LoaderWrapper from "hoc/loaderHoc";
import useTrad from "hooks/trads/trads";
import moment from "moment";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { open_drawer } from "variables/helper";
import { Order } from "service/alert";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Trads() {
  const classes = useStyles();

  const params = useParams();
  const { trads, loader, getTradsData } = useTrad();

  useEffect(() => {
    getTradsData(params?.id);
  }, []);

  const TableKeys = {
    id: (data) => {
      return <div>{data.id}</div>;
    },
    exchange: (data) => {
      return <div>{data.exchange}</div>;
    },
    currency: (data) => {
      return <div>{data.currency}</div>;
    },
    pair: (data) => {
      return <div>{data.pair}</div>;
    },

    close_date: (data) => {
      return <div>{data.close_date}</div>;
    },
    close_profit: (data) => {
      return <div>{data.close_profit}</div>;
    },
    close_profit_value: (data) => {
      return <div>{data.close_profit_value}</div>;
    },

    close_rate: (data) => {
      return <div>{data.close_rate}</div>;
    },
    close_trade_value: (data) => {
      return <div>{data.close_trade_value}</div>;
    },
    fee_close_cost: (data) => {
      return <div>{data.fee_close_cost}</div>;
    },
    fee_close_currency: (data) => {
      return <div>{data.fee_close_currency}</div>;
    },
    fee_open_cost: (data) => {
      return <div>{data.fee_open_cost}</div>;
    },

    fee_open_currency: (data) => {
      return <div>{data.fee_open_currency}</div>;
    },

    is_open: (data) => {
      return <div>{data.is_open ? "Yes" : "No"}</div>;
    },
    open_date: (data) => {
      return <div>{moment(data.open_date).format("lll")}</div>;
    },
    open_rate: (data) => {
      return <div>{data.open_rate}</div>;
    },
    open_trade_value: (data) => {
      return <div>{data.open_trade_value}</div>;
    },
    sell_order_status: (data) => {
      return <div>{data.sell_order_status}</div>;
    },
    sell_reason: (data) => {
      return <div>{data.sell_reason}</div>;
    },
    stake_amount: (data) => {
      return <div>{data.stake_amount}</div>;
    },
    stop_loss: (data) => {
      return <div>{data.stop_loss}</div>;
    },
    stop_loss_pct: (data) => {
      return <div>{data.stop_loss_pct}</div>;
    },
    stoploss_last_update: (data) => {
      return <div>{moment(data.stoploss_last_update).format("lll")}</div>;
    },
    strategy: (data) => {
      return <div>{data.strategy}</div>;
    },
    take_profit: (data) => {
      return <div>{data.take_profit}</div>;
    },
    take_profit_pct: (data) => {
      return <div>{data.take_profit_pct}</div>;
    },
    takeprofit_last_update: (data) => {
      return <div>{moment(data.takeprofit_last_update).format("lll")}</div>;
    },

    trade_side: (data) => {
      return <div>{data.trade_side}</div>;
    },
    trailing_activation_price_pct: (data) => {
      return <div>{data.trailing_activation_price_pct ?? 0}</div>;
    },
    trailing_callback_rate: (data) => {
      return <div>{data.trailing_callback_rate ?? 0}</div>;
    },
    trailing_last_update: (data) => {
      return <div>{moment(data.trailing_last_update).format("lll")}</div>;
    },
    updated_at: (data) => {
      return <div>{moment(data.updated_at).format("lll")}</div>;
    },
    action: (data) => {
      return (
        <div>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
            className="text-primary cursor-pointer w-600"
            onClick={async () => {
              open_drawer("order", {
                id: data.id,
              });
              // data.id;
            }}
          >
            View Order
          </div>
        </div>
      );
    },
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Typography variant="h4" style={{ marginBottom: "20px" }}>
          Trads
        </Typography>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Trads</h4>
            <p className={classes.cardCategoryWhite}>Trads of the Edgecharts</p>
          </CardHeader>{" "}
          <LoaderWrapper loader={loader}>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "Id",
                  "Exchange",
                  "Currency",
                  "Pair",
                  "Close date",
                  "Close profit",
                  "Close profit value",
                  "Close rate",
                  "Close trade value",
                  "Fee close cost",
                  "Fee close currency",
                  "Fee open cost",
                  "Fee open currency",
                  "Is open",
                  "Open date",
                  "Open rate",
                  "Open trade value",
                  "Sell order status",
                  "Sell reason",
                  "Stake amount",
                  "Stop loss",
                  "Stop loss pct",
                  "Stoploss last update",
                  "Strategy",
                  "Take profit",
                  "Take profit pct",
                  "Takeprofit last update",
                  "Trade side",
                  "Trailing activation price pct",
                  "Trailing callback rate",
                  "Trailing last update",
                  "Updated at",
                  "Action",
                ]}
                tableData={
                  !trads
                    ? []
                    : trads.map((data) => {
                        return Object.values(TableKeys).map((tableCell) => {
                          return tableCell(data);
                        });
                      })
                }
              />
            </CardBody>
          </LoaderWrapper>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
