import React from "react";
import Alert from "@material-ui/lab/Alert";
const ErrorBox = (props) => {
  return (
    <div className="py-3 px-3">
      {props.errors &&
        Object.keys(props.errors).map((error) => {
          return (
            <Alert severity="error" className="mb-2">
              {error != "non_field_errors" && error + " -"}

              {Array.isArray(props.errors[error])
                ? props.errors[error]?.map((er) => {
                    return <div>{er}</div>;
                  })
                : props.errors[error]}
            </Alert>
          );
        })}
    </div>
  );
};
export default ErrorBox;
