import React from "react";
import Dialog from "../components/dialog/dialog";
import { DialogClose, DialogOpen } from "../variables/rxSubjects";
class DialogHoc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      type: "",
      anchor: "left",
      dialogData: {},
      dialogModel: {},
    };

    DialogOpen.subscribe((...params) => this.open_dialog(...params));
    DialogClose.subscribe((...params) => this.close_dialog(...params));
  }

  open_dialog = (dialog) => {
    this.setState({
      ...this.state,
      dialogData: dialog[1] || {},
      open: true,
      type: dialog[0] || "",
    });
  };

  close_dialog = (flag = false) => {
    this.setState({
      ...this.state,
      open: false,
    });
  };

  render() {
    return (
      <Dialog
        open={this.state.open}
        type={this.state.type}
        handleClose={this.close_dialog}
        handlerDialog={this.open_dialog}
        dialogData={this.state.dialogData}
      />
    );
  }
}

export default DialogHoc;
