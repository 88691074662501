import React from "react";
//material ui dialog component
import Dialog from "@material-ui/core/Dialog";
//material ui dialog content
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import ConfirmDialog from "./confirmDialog/confirmDialog";
import Scanner62 from "./scanner/62-scanner";
import Scanner101 from "./scanner/101-scanner";
import Scanner163 from "./scanner/163-scanner";
import ScannerV7 from "./scanner/v7-scanner";
import ScannerV35 from "./scanner/35-scanner";
import ScannerComp10 from "./scanner/comp-10-scanner";

const styles = {
  root: {
    padding: "0px !important",
    overflowX: "hidden",
  },
  outerBox: {
    borderRadius: "8px !important",
    margin: "5px",
  },
};

const ModalDialog = (props) => {
  let { classes, dialogData } = props;

  let dialogContent = () => {
    switch (props.type) {
      case "confirm":
        return (
          <ConfirmDialog
            {...props.dialogData}
            handlerDialog={props.handlerDialog}
            onClose={props.handleClose}
          ></ConfirmDialog>
        );

      case "Scanner62":
        return (
          <Scanner62
            {...props.dialogData}
            handlerDialog={props.handlerDialog}
            onClose={props.handleClose}
          ></Scanner62>
        );
      case "Scanner163":
        return (
          <Scanner163
            {...props.dialogData}
            handlerDialog={props.handlerDialog}
            onClose={props.handleClose}
          ></Scanner163>
        );
      case "Scanner101":
        return (
          <Scanner101
            {...props.dialogData}
            handlerDialog={props.handlerDialog}
            onClose={props.handleClose}
          ></Scanner101>
        );
      case "Scannerv7":
        return (
          <ScannerV7
            {...props.dialogData}
            handlerDialog={props.handlerDialog}
            onClose={props.handleClose}
          ></ScannerV7>
        );
      case "Scannerv35":
        return (
          <ScannerV35
            {...props.dialogData}
            handlerDialog={props.handlerDialog}
            onClose={props.handleClose}
          ></ScannerV35>
        );
      case "Scannercomp10":
        return (
          <ScannerComp10
            {...props.dialogData}
            handlerDialog={props.handlerDialog}
            onClose={props.handleClose}
          ></ScannerComp10>
        );
      default:
        return <div>empty</div>;
    }
  };
  // let dialogContent = () => {
  //   switch (props.type) {
  //     case "WalletFilter":
  //       return <h1>demo</h1>;
  //       break;
  //     default:
  //       return <div>empty</div>;
  //   }
  // };

  return (
    //dialog component
    <Dialog
      test="dialog"
      open={props.open}
      onBackdropClick={
        dialogData && dialogData.dialogClick
          ? dialogData.dialogClick
          : props.handleClose
      }
      className={`${classes.outerBox} mu-dialog`}
      onClose={props.handleClose}
      disableBackdropClick={props.disableBackdropClick}
      classes={{
        paper: classes.outerBox,
      }}
      {...props.dialogModel}
    >
      <DialogContent test="DialogContent" className={classes.root}>
        <div className="w-100">{dialogContent()}</div>
      </DialogContent>
      <style jsx>{`
        :global(.mu-dialog > div > div) {
          overflow-y: visible !important;
        }
        :global(.mu-dialog) {
          margin: 11px !important;
        }

        @media only screen and (max-width: 767px) {
          :global(.mu-dialog > div > div) {
            max-width: 90vw !important;
          }
        }
      `}</style>
    </Dialog>
  );
};

export default withStyles(styles)(ModalDialog);
