import {
  DialogClose,
  DialogOpen,
  drawerClose,
  drawerOpen,
  snakbar,
} from "./rxSubjects";
// for show toast message
export const Toast = (message, type = "success", duration = 2000) => {
  snakbar.next({
    message,
    type,
    duration,
  });
};

// for open dialog
export const open_dialog = (...params) => {
  DialogOpen.next([...params]);
};

// for close dialog
export const close_dialog = (...params) => {
  DialogClose.next(...params);
};

// for open drawer
export const open_drawer = (...params) => {
  drawerOpen.next([...params]);
};

// for close drawer
export const close_drawer = (...params) => {
  drawerClose.next(...params);
};
