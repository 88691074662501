import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import HeatChats from "components/charts/heatCharts";
import DatePicker from "components/datePicker/datePicker";
import GridItem from "components/Grid/GridItem.js";
import LoaderWrapper from "hoc/loaderHoc";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { formateDate, formateDateOnly, scrollToTop } from "utils/helper";
// import Button from "../../components/CustomButtons/Button";
import Table from "../../components/Table/Table.js";
import useAlertsLogs from "../../hooks/alert-logs/alert-logs";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const AlertLogs = (props) => {
  const TableKeys = {
    // name: (data) => {
    //   return <div>{data.name}</div>;
    // },
    message: (data) => {
      return <div>{data.message}</div>;
    },
    created_at: (data) => {
      return (
        <div>{moment(data.created_at).format("MMMM Do YYYY, h:mm:ss a")}</div>
      );
    },
    expiration: (data) => {
      return <div>{data.expiration}</div>;
    },
    is_used: (data) => {
      return <div>{data.is_used ? "Yes" : "No"}</div>;
    },
  };
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  const [constDate, setDate] = useState("");
  const { getAlertLogs, alertsLogs, loader, setLoader } = useAlertsLogs();
  const {
    getAlertLogs: getSubAlert,
    loader: subAlertLoader,
    setalertsLogs,
    alertsLogs: alertLogsMinut,
  } = useAlertsLogs();
  const {
    getLogs: getLogsData,
    loader: logsLoader,
    setLogs,
    logs,
  } = useAlertsLogs();

  const [filter, setFilter] = useState({
    id: params.id,
    date: Date.now(),
    timefram: "h",
  });

  const getLogs = () => {
    const newFilter = { ...filter };
    if (params.alert) {
      newFilter["flag"] = 2;
      newFilter["sub_alert_id"] = params.alert;
    }
    getAlertLogs(newFilter);
  };
  // get alert logs
  useEffect(() => {
    getLogs();
    scrollToTop();
    setalertsLogs(null);
    setLogs(null);
  }, [filter, params]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <Typography variant="h4" style={{ marginBottom: "20px" }}>
          {params.alert ? `Sub Alerts Of ${params.label}` : "Alert logs"}
        </Typography>
        <div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              history.replace("/admin/alert-list/");
            }}
          >
            Back to Alerts
          </Button>
        </div>
      </div>
      <GridItem className="d-flex justify-content-between align-align-items-center">
        <div className="d-flex align-items-center">
          {" "}
          {/* <Button variant="outlined" color="secondary" className="mr-3">
            Logs in hour
          </Button>
          <Button variant="outlined" color="secondary">
            Logs in minuts
          </Button> */}
        </div>

        <div>
          <DatePicker
            onDateSelect={(date) => {
              setLoader(true);
              setFilter((prevFilter) => {
                return {
                  ...prevFilter,
                  date: date,
                };
              });
            }}
          ></DatePicker>
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader
            color="primary"
            className="d-flex justify-content-between"
          >
            <h4 className={classes.cardTitleWhite + " pb-4"}> Alert logs</h4>
            <p className={classes.cardCategoryWhite}></p>
            {params.alert && (
              <div>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back to main alerts logs
                </Button>
              </div>
            )}
          </CardHeader>{" "}
          <LoaderWrapper loader={loader}>
            <CardBody className="hour-charts">
              {!loader && (
                <HeatChats
                  {...alertsLogs}
                  onLabelClick={(label) => {
                    if (alertsLogs["subAlert"][label] && !params.alert) {
                      history.push(
                        `/admin/alert-list/alert-logs/${params.id}/${label}/${alertsLogs["subAlert"][label].id}`
                      );
                    }
                  }}
                  onGraphClick={(xaxis, yaxis) => {
                    const date = alertsLogs.data[yaxis][xaxis][0];

                    setDate(formateDate(date));
                    const requestPayload = {
                      id: params.id,
                      date: date,
                      timefram: "m",
                    };

                    if (params.alert) {
                      requestPayload["flag"] = 2;
                      requestPayload["sub_alert_id"] = params.alert;
                      getLogsData(requestPayload);
                    }

                    getSubAlert(requestPayload);
                  }}
                  disabledLabelClick={params.alert}
                  subAlerts={alertsLogs && alertsLogs["subAlert"]}
                  options={{
                    yaxis: {
                      title: {
                        text: formateDateOnly(filter.date),
                      },
                    },
                  }}
                ></HeatChats>
              )}
            </CardBody>
          </LoaderWrapper>
        </Card>

        {(subAlertLoader || alertLogsMinut) && (
          <Card>
            <LoaderWrapper loader={subAlertLoader}>
              {/* <h4 className={"pb-4"}> Alerts logs in minuts</h4> */}
              <CardBody>
                {!subAlertLoader && !loader && (
                  <HeatChats
                    disabledLabelClick={params.alert}
                    subAlerts={alertsLogs && alertsLogs["subAlert"]}
                    onLabelClick={(label) => {
                      if (alertsLogs["subAlert"][label] && !params.alert) {
                        history.push(
                          `/admin/alert-list/alert-logs/${params.id}/${label}/${alertsLogs["subAlert"][label].id}`
                        );
                      }
                    }}
                    inverse={true}
                    ranges={[
                      {
                        from: 1,
                        to: 1,
                        name: "1",
                        color: "#dfbde6",
                      },
                      {
                        from: 2,
                        to: 2,
                        name: "2",
                        color: "#a03ab5",
                      },
                      {
                        from: 3,
                        to: 4,
                        name: "3-unlimited",
                        color: "#775dd0",
                      },

                      // {
                      //   from: 2,
                      //   to: 9999,
                      //   name: "Range 2-unlimited",
                      //   color: "#008000",
                      // },
                    ]}
                    options={{
                      title: {
                        text: constDate,
                      },
                      yaxis: {
                        title: {
                          text: constDate,
                        },
                      },
                    }}
                    {...alertLogsMinut}
                  ></HeatChats>
                )}
              </CardBody>
            </LoaderWrapper>
          </Card>
        )}

        {(logsLoader || logs) && (
          <Card>
            <LoaderWrapper loader={logsLoader}>
              {/* <h4 className={"pb-4"}> Alerts logs in minuts</h4> */}
              <CardBody>
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "Message",
                    "Trigger-time",
                    "Expiration",
                    "Is Used",
                  ]}
                  tableData={
                    !logs
                      ? []
                      : logs.map((data) => {
                          return Object.values(TableKeys).map((tableCell) => {
                            return tableCell(data);
                          });
                        })
                  }
                />{" "}
              </CardBody>
            </LoaderWrapper>
          </Card>
        )}
        {/* <div>alert losgs </div> */}
      </GridItem>
    </div>
  );
};

export default AlertLogs;
