// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CardBody from "components/Card/CardBody";
// import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import Table from "components/Table/Table.js";
import LoaderWrapper from "hoc/loaderHoc";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Order } from "service/alert";

import CloseIcon from "@material-ui/icons/Close";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Orders({ id, onClose }) {
  const classes = useStyles();

  const TableKeys = {
    symbol: (data) => {
      return <div>{data.symbol}</div>;
    },
    amount: (data) => {
      return <div>{data.amount}</div>;
    },
    cost: (data) => {
      return <div>{data.cost}</div>;
    },
    filled: (data) => {
      return <div>{data.filled}</div>;
    },

    ft_is_open: (data) => {
      return <div>{data.ft_is_open}</div>;
    },
    ft_pair: (data) => {
      return <div>{data.ft_pair}</div>;
    },

    ft_order_side: (data) => {
      return <div>{data.ft_order_side}</div>;
    },
    ft_trade_id: (data) => {
      return <div>{data.ft_trade_id}</div>;
    },

    order_date: (data) => {
      return <div>{moment(data.order_date).format("lll")}</div>;
    },
    order_filled_date: (data) => {
      return <div>{moment(data.order_filled_date).format("lll")}</div>;
    },
    order_id: (data) => {
      return <div>{data.order_id}</div>;
    },
    order_type: (data) => {
      return <div>{data.order_type}</div>;
    },
    order_update_date: (data) => {
      return <div>{moment(data.order_update_date).format("lll")}</div>;
    },
    price: (data) => {
      return <div>{data.price}</div>;
    },
    remaining: (data) => {
      return <div>{data.remaining}</div>;
    },
    side: (data) => {
      return <div>{data.side}</div>;
    },

    status: (data) => {
      return <div>{data.status}</div>;
    },
  };
  const [orders, setOrders] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    Order(id).then((data) => {
      setOrders(data.data);
      setLoader(false);
    });
  }, []);

  return (
    <div
      className="overflow-hidden d-flex flex-column"
      style={{
        width: "80vw",
        overflowX: "hidden",
        height: "100vh",
      }}
    >
      <div className="d-flex p-3 border border-b justify-content-between">
        <h5 className="mb-0 w-600">Order</h5>
        <div onClick={onClose} className="cursor-pointer">
          <CloseIcon></CloseIcon>
        </div>
      </div>
      <div className="h-100 h-100">
        <LoaderWrapper loaderClass="pt-0 mt-0" loader={loader}>
          <div className="w-100 h-100vh overflow-hidden order-table">
            <Table
              tableHeaderColor="primary nowape"
              tableHead={[
                "Symbol",
                "Amount",
                "Cost",
                "Filled",
                "Ft is open",
                "Ft order side",
                "Ft pair",
                "Ft trade id",
                "Order date",
                "Order filled date",
                "Order id",
                "Order type",
                "Order update date",
                "Price",
                "Remaining",
                "Side",
                "Status",
              ]}
              tableData={
                orders
                  ? [...orders].map((data) => {
                      return Object.values(TableKeys).map((tableCell) => {
                        return tableCell(data);
                      });
                    })
                  : []
              }
            />
          </div>
        </LoaderWrapper>
      </div>
    </div>
  );
}
