// import { DialogContext } from "../context/context";
import React, { useEffect, useState } from "react";
import Drawer from "components/drawer/drawer";
import { drawerOpen } from "variables/rxSubjects";
import { drawerClose } from "variables/rxSubjects";

const DrawerContext = (props) => {
  let [state, setState] = useState({
    drawer: {
      open: false,
      type: "",
      anchor: "left",
      drawerData: {},
      drawerModel: {},
    },
  });

  useEffect(() => {
    drawerOpen.subscribe((...params) => {
      open_drawer(...params[0]);
    });
    drawerClose.subscribe((...params) => close_drawer(...params));
  }, []);

  let open_drawer = (
    type,
    drawerData = {},
    anchor = "left",
    drawerModel = {}
  ) => {
    setState({
      ...state,
      drawer: {
        ...state.drawer,
        open: true,
        anchor,
        type: type,
        drawerData: drawerData,
        drawerModel: drawerModel,
      },
    });
    //   () => {
    //   }
    // );
  };

  let close_drawer = (flag = false) => {
    setState({
      ...state,
      drawer: {
        ...state.drawer,
        open: false,
      },
    });
  };

  return (
    <Drawer
      open={state.drawer.open}
      type={state.drawer.type}
      handleClose={close_drawer}
      anchor={state.drawer.anchor}
      handlerDialog={open_drawer}
      drawerData={state.drawer.drawerData}
    />
  );
};

export default DrawerContext;
