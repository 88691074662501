import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CustomInput from "components/CustomInput/CustomInput";
import React, { useState } from "react";
import Button from "../../CustomButtons/Button";
const ConfirmDialog = (props) => {
  const [text, setText] = useState("");
  return (
    <div>
      <DialogTitle id="alert-dialog-title" className="pb-0">
        {props?.title || "Are you sure you want to delet it ?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description"></DialogContentText>
        <CustomInput
          labelText="Reason"
          id="name"
          inputProps={{
            value: text,
            name: "name",
            onChange: (e) => setText(e.target.value),
          }}
          formControlProps={{
            fullWidth: true,
            className: "mt-0 pt-0",
          }}
        />
      </DialogContent>

      <div className=" pr-3 mt-2 pb-3 d-flex justify-content-end">
        <Button
          color="primary"
          onClick={() => {
            props.onClose();
          }}
        >
          Close
        </Button>
        <Button
          disabled={!text}
          className="ml-3"
          variant="outlined"
          color="primary"
          onClick={() => {
            props.onSubmit();
          }}
        >
          {props?.confirmButtonText || "Delete"}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmDialog;
