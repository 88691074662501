import { SCANNER_URL } from "variables/config";
import { HISTORY, TRADING_PAIR } from "./endpoint";

const { post, get, deleteReq, patch } = require("./service-methods");

export const scannerRequest = (url, payload) => {
  return post(SCANNER_URL + url, payload);
};

export const scannerStrategy = (payload) => {
  return post(HISTORY, {
    ...payload,
    url: SCANNER_URL + payload.url,
  });
};

export const getscannerStrategy = (option = {}) => {
  return get(HISTORY, option);
};

export const getscannerStrategyById = (id, option = {}) => {
  return get(HISTORY + id + "/", option);
};

export const deleteScannerStrategy = (id, option = {}) => {
  return deleteReq(HISTORY + id + "/", option);
};

export const updateScannerStrategy = (id, data, option = {}) => {
  return patch(HISTORY + id + "/", data, option);
};

export const getTradingPairs = (id, data, option = {}) => {
  return get(TRADING_PAIR);
};
