import moment from "moment";
import { v4 as uuidv4 } from "uuid";
export const getId = () => {
  return uuidv4();
};
export const getStorage = (key) => {
  if (localStorage.getItem(key)) {
    return atob(localStorage.getItem(key));
  } else {
    return undefined;
  }
};
export const setStorage = (key, data = "") => {
  return localStorage.setItem(key, btoa(data));
};
export const getAuthData = () => {
  let data = getStorage("root");

  if (data) {
    data = JSON.parse(data);
  } else {
    data = {};
  }
  return data;
};

export const isAdmin = () => {
  const auth = getAuthData();
  return true;
};
export const Logout = () => {
  localStorage.removeItem("root");
  window.location.reload();
};

export const getFirstPath = (menus) => {
  const SideBarLink = menus || getAuthData().sideMenu || [];
  const path = !SideBarLink?.[0]?.children
    ? SideBarLink?.[0]?.path
    : SideBarLink?.[0]?.children?.[0]?.path;

  return "/admin" + path;
};

export const getHourMinuts = (date) => {
  return moment(date).format("hh:mm A");
};

export const getHourMinutsutc = (date) => {
  return moment(date).utcOffset(0, true).format("hh:mm A");
};

export const convertTimeStamp = (date) => {
  return moment(date).unix();
};

export const convertDateToUtc = (date) => {
  // console.log*()
  return moment.utc(date.toString()).local();
};
export const convertToUtc = (date) => {
  var utcDate = moment(date).utc();
  return moment(utcDate);
};
export const appendDateTIme = (date, time) => {
  let dateonly = moment(date).format("DD/MM/YYYY");
  let newDate = dateonly + " " + time;
  return moment(newDate, "DD-MM-YYYY hh:mm A");
};

export const formateDate = (date) => {
  return moment(date).format("DD-MM-YYYY hh:mm A");
};

export const convertLocalTimeZone = (date) => {
  var testDateUtc = moment.utc(date);
  var localDate = testDateUtc.local();
  return moment(localDate);
};

export const scrollToTop = () => {
  const sideSection = document.getElementById("sideSection");

  if (sideSection) {
    sideSection.scrollTo({ top: 0, behavior: "smooth" });
  }
  // sideSection & sideSection.scrollIntoView({ top: 0, behavior: "smooth" });
};

export const formateDateOnly = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

export const getColorValue = (data, ts, condition) => {
  if (data["bluetrend_" + ts]) {
    return 1;
  } else if (data["downtrend_" + ts]) {
    return 2;
  } else if (data["neutraltrend_" + ts]) {
    return 3;
  } else if (data["orangetrend_" + ts]) {
    return 4;
  } else if (data["uptrend_" + ts]) {
    return 5;
  } else if (data["up_" + ts]) {
    return 5;
  } else if (data["dn_" + ts]) {
    return 2;
  } else if (condition == "101") {
    return 3;
  }

  return 0;
  // return {
  //   ["blue_band_" + ts]: 1,
  //   ["green_band_" + ts]: 2,
  //   ["neutral_band_" + ts]: 3,
  //   ["orange_band_" + ts]: 4,
  //   ["purple_band_" + ts]: 5,
  //   ["red_band_" + ts]: 6,
  //   ["yellow_band_" + ts]: 7,
  //   ["unavailable_pair_" + ts]: 8,
  // };
};
