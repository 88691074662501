export const ACCOUNTS = "/exchange-accounts/";
export const ALERT = "/strategy/";
export const AUTO_ALERT = "/auto-strategy/";
export const AUTO_ALERT_ROW = "/rawscanner/";
export const AUTO_STRATEGY_ROW = "/raw-auto-strategy/";

export const AUTO_STRATERGY_HISTORICAL = "/auto-strategy/historical/";

export const SUB_ALERT = "/alerts/";
export const GET_SUB_ALERTS = "/aggregator-alerts/";
export const SUB_ALERT_GROUP = "/auto-group/";
export const AUTH = "/api-token-auth/";
export const DISCORD = "/discord-channels/";
export const ALERT_LOGS = "/alertlogs-chart/";
export const ALERT_LOGS_DATA = "/alert-logs/";
export const GROUP = "/strategy";
export const BULKSUBALERT = "/bulksubalerts/";
export const SUBALERT = "/subalerts/";
export const STOP_STRATEGY = "/stop-strategy/";
export const TRADS = "/trades/";
export const ORDER = "/orders/";
export const V562CONDITION = "/api/v1/v5-62-conditions";
export const V1163CONDITION = "/api/v1/v163";
export const V2101CONDITION = "/api/v1/v2-101-conditions";
export const V262101CONDITION = "/api/v1/v562-v2101-conditions";
export const V235CONDITION = "/api/v1/v35";
export const V2CAMPCONDITION = "/api/v1/comp10/";

export const USERS = "/users/";
export const HISTORY = "/scanner-strategy/";
export const HEATMAP_62 = "/api/v1/hm/v5-62/";
export const HEATMAP_101 = "/api/v1/hm/v2-101/";
export const HEATMAP_163 = "/api/v1/hm/v163/";
export const HEATMAP_V7 = "/api/v1/hm/v7/";
export const HEATMAP_35 = "/api/v1/hm/v35/";
export const HEATMAP_COMP10 = "/api/v1/hm/comp10/";

export const SCANNER = "/scanner/";
export const SCANNER_HEATMAP = "/autoscan-heatmap/";
export const TRADING_PAIR = "/asset/";
export const DASHBORD_163_v3 = "/dashboard/v3_163";
export const DASHBORD_163_v5 = "/dashboard/v5_163";
export const DASHBORD_163_v3_2 = "/dashboard/v3_163_2";
export const DASHBORD_163_v5_2 = "/dashboard/v5_163_2";

export const DASHBORD_163110 = "/dashboard/v5_163_3";
export const DASHBORD_163130 = "/dashboard/v5_163_4";
export const DASHBORD = "/dashboard/";
export const BACKTESTER = "/auto-strategy/backtest/";
export const BACKTESTER_HISTORY = "/api/v1/backtest/history/";
export const BACKTESTER_HISTORY_DEMO = "/api/v1/backtest/history/result";
export const BACKTESTER_STATUS = "/api/v1/backtest";
export const BACKTESTER_ABORT = "/api/v1/backtest/abort";

// http://142.132.208.113:7000/api/v1/hm/v163
