import AddAlertIcon from "@material-ui/icons/AddAlert";
import Notifications from "@material-ui/icons/Notifications";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DocumentScannerIcon from "@material-ui/icons/Scanner";
import Group from "@material-ui/icons/Group";
import React from "react";
import AlertLogs from "views/alert-logs/alert-logs";
import Trads from "views/trads/trads";
import { GraphicEq, TableChart } from "@material-ui/icons";
import AutoGraphIcon from "@material-ui/icons/Autorenew";
const DiscordIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // xmlns:svgjs="http://svgjs.com/svgjs"
      version="1.1"
      width={20}
      height={20}
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{ enableBackground: "new 0 0 512 512", marginRight: "15px" }}
      xmlSpace="preserve"
    >
      <g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m3.58 21.196h14.259l-.681-2.205 1.629 1.398 1.493 1.338 2.72 2.273v-21.525c-.068-1.338-1.22-2.475-2.648-2.475l-16.767.003c-1.427 0-2.585 1.139-2.585 2.477v16.24c0 1.411 1.156 2.476 2.58 2.476zm10.548-15.513-.033.012.012-.012zm-7.631 1.269c1.833-1.334 3.532-1.27 3.532-1.27l.137.135c-2.243.535-3.26 1.537-3.26 1.537s.272-.133.747-.336c3.021-1.188 6.32-1.102 9.374.402 0 0-1.019-.937-3.124-1.537l.186-.183c.291.001 1.831.055 3.479 1.26 0 0 1.844 3.15 1.844 7.02-.061-.074-1.144 1.666-3.931 1.726 0 0-.472-.534-.808-1 1.63-.468 2.24-1.404 2.24-1.404-.535.337-1.023.537-1.419.737-.609.268-1.219.4-1.828.535-2.884.468-4.503-.315-6.033-.936l-.523-.266s.609.936 2.174 1.404c-.411.469-.818 1.002-.818 1.002-2.786-.066-3.802-1.806-3.802-1.806 0-3.876 1.833-7.02 1.833-7.02z"
          fill="#ffffff"
          data-original="#000000"
          style={{}}
          className
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m14.308 12.771c.711 0 1.29-.6 1.29-1.34 0-.735-.576-1.335-1.29-1.335v.003c-.708 0-1.288.598-1.29 1.338 0 .734.579 1.334 1.29 1.334z"
          fill="#ffffff"
          data-original="#000000"
          style={{}}
          className
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m9.69 12.771c.711 0 1.29-.6 1.29-1.34 0-.735-.575-1.335-1.286-1.335l-.004.003c-.711 0-1.29.598-1.29 1.338 0 .734.579 1.334 1.29 1.334z"
          fill="#ffffff"
          data-original="#000000"
          style={{}}
          className
        />
      </g>
    </svg>
  );
};
const dashboardRoutes = [
  {
    path: "/",
    name: "Login",
    layout: "/",
    component: React.lazy(() => import("../src/views/login/login")),
  },
  {
    path: "/accounts/create-accounts/:id?",
    name: "Create account",
    id: "accounts",
    component: React.lazy(() => import("../src/views/account/account")),
    layout: "/admin",
  },
  {
    path: "/alert-list/trads/:id",
    name: "Trads",
    id: "alert-list",
    component: Trads,
    layout: "/admin",
  },
  {
    path: "/alert-list/alert-logs/:id?/:label?/:alert?",
    name: "Alerts Logs",
    id: "alert-list",
    component: AlertLogs,
    layout: "/admin",
  },
  {
    path: "/alert-list",
    name: "Alerts",
    id: "alert-list",
    component: React.lazy(() => import("../src/views/AlertList/alertList")),
    layout: "/admin",
  },
  {
    path: "/auto-strategy-list/auto-strategy/:id?",
    name: "Alerts",
    id: "auto-strategy-list",
    component: React.lazy(() =>
      import("../src/views/autostrategy/autostrategy")
    ),
    layout: "/admin",
  },

  {
    path: "/auto-strategy-list/chart/:name?/:id?",
    name: "Auto Strategy-list",
    id: "auto-strategy-list",
    component: React.lazy(() =>
      import("../src/views/auto-stratergy-list/auto-stratergy-chart")
    ),
    layout: "/admin",
  },

  {
    path: "/auto-strategy-list",
    name: "Auto Strategy-list",
    id: "auto-strategy-list",
    component: React.lazy(() =>
      import("../src/views/auto-stratergy-list/auto-stratergy-list")
    ),
    layout: "/admin",
  },

  {
    path: "/auto-strategy-list/auto-strategy/alert-logs/:id?/:label?/:alert?",
    name: "Alerts Logs",
    id: "auto-strategy-list",
    component: AlertLogs,
    layout: "/admin",
  },

  {
    path: "/create-alert/:id?",
    name: "Create New Alert",
    id: "alert-list",

    component: React.lazy(() => import("../src/views/alert/alert")),
    icon: "content_paste",

    layout: "/admin",
  },

  {
    path: "/accounts",
    name: "Accounts",
    id: "accounts",
    component: React.lazy(() => import("../src/views/AccountList/accountList")),
    layout: "/admin",
  },

  {
    path: "/discord-chanels/create-discord-account/:id?",
    name: "Create Discord Account",
    id: "discord-chanels",
    component: React.lazy(() =>
      import(
        "../src/views/discord-chanels/create-discord-chanels/create-discord-chanel"
      )
    ),
    layout: "/admin",
  },
  {
    path: "/discord-chanels",
    name: "Discord Channels",
    id: "discord-chanels",
    component: React.lazy(() =>
      import("../src/views/discord-chanels/discord-chanels")
    ),
    layout: "/admin",
  },

  {
    path: "/scanners/62-condition/:id?/:token?/:type?",
    name: "Scanner",
    id: "scanners",

    component: React.lazy(() => import("../src/views/scanner/62-condition")),
    layout: "/admin",
  },
  {
    path: "/scanners/101-condition/:id?/:token?/:type?",
    name: "Scanner",
    id: "scanners",

    component: React.lazy(() => import("../src/views/scanner/101-condition")),
    layout: "/admin",
  },
  {
    path: "/scanners/163-condition/:id?/:token?/:type?",
    name: "Scanner",
    id: "scanners",

    component: React.lazy(() => import("../src/views/scanner/163-condition")),
    layout: "/admin",
  },
  {
    path: "/scanners/62-101-condition/:id?/:token?/:type?",
    name: "Scanner",
    id: "scanners",

    component: React.lazy(() =>
      import("../src/views/scanner/62-101-condition")
    ),
    layout: "/admin",
  },

  {
    path: "/scanners/62-101-163-condition/:id?/:token?/:type?",
    name: "Scanner",
    id: "scanners",

    component: React.lazy(() =>
      import("../src/views/scanner/62-101-163-condition")
    ),
    layout: "/admin",
  },
  {
    path: "/scanners/35-condition/:id?/:token?/:type?",
    name: "Scanner",
    id: "scanners",
    component: React.lazy(() => import("../src/views/scanner/35-condition")),
    layout: "/admin",
  },
  {
    path: "/scanners/camp10-condition/:id?/:token?/:type?",
    name: "Scanner",
    id: "scanners",
    component: React.lazy(() =>
      import("../src/views/scanner/comp-10-condition")
    ),
    layout: "/admin",
  },

  {
    path: "/scanners/scanner-chain/:id?/:token?/:type?",
    name: "Scanner",
    id: "scanners",

    component: React.lazy(() => import("../src/views/scanner/scanner-chain")),
    layout: "/admin",
  },

  {
    path: "/users/user/:id?",
    name: "users",
    id: "users",
    component: React.lazy(() => import("../src/views/users/add-update-user")),
    layout: "/admin",
  },
  {
    path: "/users/",
    id: "users",

    name: "users",
    component: React.lazy(() => import("../src/views/users/users")),
    layout: "/admin",
  },
  {
    path: "/trading-pair/chart/:pair?/:scanner?",
    name: "Trading Pairs",
    id: "trading-pair",

    component: React.lazy(() => import("../src/views/trading-pair/chart")),
    layout: "/admin",
  },
  {
    path: "/trading-pair",
    name: "Trading Pairs",
    id: "trading-pair",

    component: React.lazy(() =>
      import("../src/views/trading-pair/trading-pair")
    ),
    layout: "/admin",
  },

  {
    path: "/auto-scanner/chart/:name?/:id?",
    name: "Auto Scanners",
    id: "auto-scanner",
    component: React.lazy(() =>
      import("../src/views/auto-scanner/auto-scanner-heatmap")
    ),
    auth: "user",
    layout: "/admin",
  },
  {
    path: "/backtester/trades/:name/:strategy",
    name: "Backtester",
    id: "backtester",
    component: React.lazy(() => import("../src/views/backtester/trads")),
    auth: "user",
    layout: "/admin",
  },
  {
    path: "/backtester/:tab?",
    name: "Backtester",
    id: "backtester",
    component: React.lazy(() => import("../src/views/backtester/backtester")),
    auth: "user",
    layout: "/admin",
  },
  {
    path: "/auto-scanner/:page?/:id?",
    name: "Auto Scanners",
    id: "auto-scanner",
    component: React.lazy(() =>
      import("../src/views/auto-scanner/auto-scanner")
    ),
    layout: "/admin",
    auth: "user",
  },
  {
    path: "/dashbord/:name/:id",
    name: "Dashbord",
    id: "dashbord",
    component: React.lazy(() => import("../src/views/dashbord/dashbord-chart")),
    layout: "/admin",
    auth: "user",
  },
  {
    path: "/dashbord",
    name: "Dashbord",
    id: "dashbord",
    component: React.lazy(() => import("../src/views/dashbord/dashbord")),
    layout: "/admin",
    auth: "user",
  },
  // {
  //   path: "/dashbord/163-v3",
  //   name: "Dashbord 163 V3",
  //   id: "dashbord",

  //   component: React.lazy(() =>
  //     import("../src/views/dashbord/dashbord-163-v3")
  //   ),
  //   layout: "/admin",
  //   auth: "user",
  // },
  // {
  //   path: "/dashbord/163-v5",
  //   name: "Dashbord 163 V5",
  //   id: "dashbord",

  //   component: React.lazy(() =>
  //     import("../src/views/dashbord/dashbord-163-v5")
  //   ),
  //   layout: "/admin",
  //   auth: "user",
  // },
  // {
  //   path: "/dashbord/163-v3-2",
  //   name: "Dashbord 163 V3 2",
  //   id: "dashbord",
  //   component: React.lazy(() =>
  //     import("../src/views/dashbord/dashbord-163-v3-2")
  //   ),
  //   layout: "/admin",
  //   auth: "user",
  // },
  // {
  //   path: "/dashbord/163-v5-2",
  //   name: "Dashbord 163 V5 2",
  //   id: "dashbord",
  //   component: React.lazy(() =>
  //     import("../src/views/dashbord/dashbord-163-v5-2")
  //   ),
  //   layout: "/admin",
  //   auth: "user",
  // },
  // {
  //   path: "/dashbord/163-v5-3",
  //   name: "Dashbord 163 V5 3",
  //   id: "dashbord",
  //   component: React.lazy(() =>
  //     import("../src/views/dashbord/dashbord-163-v5-3")
  //   ),
  //   layout: "/admin",
  //   auth: "user",
  // },
  // {
  //   path: "/dashbord/163-v5-4",
  //   name: "Dashbord 163 V5 4",
  //   id: "dashbord",
  //   component: React.lazy(() =>
  //     import("../src/views/dashbord/dashbord-163-v5-4")
  //   ),
  //   layout: "/admin",
  //   auth: "user",
  // },
  // {
  //   path: "/dashbord/163-v5-5",
  //   name: "Dashbord 163 V5 5",
  //   id: "dashbord",
  //   component: React.lazy(() =>
  //     import("../src/views/dashbord/dashbord-163-v5-5")
  //   ),
  //   layout: "/admin",
  //   auth: "user",
  // },
];

export default dashboardRoutes;

export const Icons = {
  dashbord: {
    icon: TableChart,
  },
  backtester: {
    icon: Notifications,
  },
  "alert-list": {
    icon: Notifications,
  },
  "auto-strategy-list": {
    icon: Notifications,
  },
  "auto-strategy": {
    icon: AddAlertIcon,
  },
  "create-alert": {
    icon: AddAlertIcon,
  },

  accounts: {
    icon: SupervisorAccountIcon,
  },
  "discord-chanels": {
    icon: DiscordIcons,
  },
  scanners: {
    icon: DocumentScannerIcon,
  },
  users: {
    icon: Group,
  },
  "trading-pair": {
    icon: GraphicEq,
  },
  "auto-scanner": {
    icon: AutoGraphIcon,
  },
};
