import React, { useState } from "react";
import { updateScannerStrategy } from "service/scanner";
import { getscannerStrategyById } from "service/scanner";
import { deleteScannerStrategy } from "service/scanner";
import { getscannerStrategy } from "service/scanner";
import { scannerRequest } from "service/scanner";

const useScanner = () => {
  const [scannerRespnse, setScannerData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [historyLoader, setHistoryLoader] = useState(false);
  const [history, setHistory] = useState(null);

  const fetchScannerData = async (url, payload) => {
    try {
      setLoader(true);
      const response = await scannerRequest(url, payload);
      setScannerData(response?.data);
      setLoader(false);
    } catch (e) {
      setScannerData({});
      setLoader(false);
    }
  };

  const deleteHistory = async ({ id, cb, option = {} }) => {
    setHistoryLoader(true);
    try {
      await deleteScannerStrategy(id, option);
      cb && cb();
      getHistory();
    } catch (e) {
      setHistoryLoader(false);
    }
  };
  const updatePublicPrivet = async ({ id, data, i, cb, option = {} }) => {
    try {
      await updateScannerStrategy(id, data, option);
      const historyTemp = [...history];
      historyTemp[i].is_public = data?.is_public;
      setHistory(historyTemp);
      cb && cb();
    } catch (e) {
      setHistoryLoader(false);
    }
  };
  const getHistory = async (option) => {
    setHistoryLoader(true);
    try {
      const response = await getscannerStrategy(option);
      setHistory(response?.data);
      setHistoryLoader(false);
    } catch (e) {
      setHistoryLoader(false);
    }
  };

  const getHistoryById = async (id, cb, option = {}) => {
    try {
      const response = await getscannerStrategyById(id, option);
      cb && cb(response?.data);
    } catch (e) {}
  };

  return {
    deleteHistory,

    getHistoryById,
    loader,
    history,
    getHistory,
    updatePublicPrivet,
    setLoader,
    historyLoader,
    fetchScannerData,
    scannerRespnse,
  };
};

export default useScanner;
