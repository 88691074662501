import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { makeStyles, withStyles } from "@material-ui/styles";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import LoaderWrapper from "hoc/loaderHoc";
import useScanner from "hooks/scanner/useScanner";
import useTradingPair from "hooks/trading-pair/useTradingPair";
import React, { useEffect, useState } from "react";
import { get62ConditionHeatMap } from "service/chart";
import { isAdmin } from "utils/helper";
import { colors } from "variables/config";

const CustomCheckbox = withStyles({
  root: {
    color: "#9c27b0",
    "&$checked": {
      color: "#9c27b0",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);
const Scanner62 = ({ pair, onSubmit, onClose, data }) => {
  const { pairs } = useTradingPair();

  const classes = useStyles();
  const intialState = (data = {}) => {
    return {
      tfs: data.tfs || [],
      pairs: data.pairs || [],
      days: data?.days || 1,
    };
  };
  const admin = isAdmin();

  const [strict, setStrict] = useState(false);

  const [scannerData, setScannerData] = useState(intialState());
  const [tfs, setTfs] = useState({
    unit: "h",
    tfs: "",
  });
  const [chartTf, setChartTf] = useState({
    unit: "h",
    chartTf: "",
  });

  const [filterBy, setFilterBy] = useState("any");
  const [scannerRespnse, setScannerResponse] = useState(null);
  const [loader, setLoader] = useState(false);

  const { getHistoryById } = useScanner();

  const deleteTfs = (index) => {
    setScannerData({
      ...scannerData,
      tfs: scannerData.tfs?.filter((_, i) => {
        return i != index;
      }),
    });
  };

  useEffect(() => {
    data && setScannerData(intialState(data));
    data && setChartTf(data?.chart_tf);
  }, []);
  const handleSubmit = async () => {
    setLoader(true);
    const requestPayload = {
      tfs: scannerData.tfs,
      chart_tf: chartTf.chartTf + chartTf.unit,
      days: scannerData.days,
      pair: pair?.replace("-", "/"),
    };
    try {
      const response = await get62ConditionHeatMap(requestPayload);
      onSubmit(response?.data, { ...scannerData, chart_tf: chartTf });
      onClose();
    } catch (e) {}

    setLoader(false);
  };

  const handleForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (value[value.length - 1] === "all") {
      setScannerData({
        ...scannerData,
        [name]: scannerData?.pairs.length === pairs?.length ? [] : pairs,
      });
      return;
    }

    admin &&
      setScannerData({
        ...scannerData,
        [name]: value,
      });
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <LoaderWrapper minWidthSet={false}>
            <CardBody>
              <h3 className="mt-0">
                <b>62 Condition</b>
              </h3>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (tfs && admin) {
                        const multiTfs = tfs?.tfs?.split(" ")?.map((tf) => {
                          return {
                            tf: tf + tfs?.unit,
                            color: filterBy,
                            strict: strict,
                          };
                        });
                        setScannerData({
                          ...scannerData,
                          tfs: [...scannerData.tfs, ...multiTfs],
                        });

                        setTfs({ tfs: "", unit: "h" });
                        setFilterBy("any");
                        setStrict(false);
                      }
                    }}
                  >
                    <GridContainer alignItems="center">
                      <GridItem>
                        <FormControl className="">
                          <CustomInput
                            labelText="TFS"
                            id="tfs"
                            inputProps={{
                              disabled: !admin,

                              value: tfs.tfs,
                              type: "text",
                              name: "tfs",
                              onChange: (e) => {
                                admin &&
                                  setTfs({
                                    ...tfs,
                                    tfs: e.target.value,
                                  });
                              },
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl className="mt-3" style={{ width: "90px" }}>
                          <InputLabel className="label" id="unit">
                            Unit
                          </InputLabel>
                          <Select
                            id="unit"
                            name="unit"
                            disabled={!admin}
                            value={tfs?.unit}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            onChange={(e) => {
                              admin &&
                                setTfs({
                                  ...tfs,
                                  unit: e.target.value,
                                });
                            }}
                          >
                            {[
                              {
                                label: "Hours",
                                value: "h",
                              },
                              { label: "Minutes", value: "m" },
                            ].map((_, index) => {
                              return (
                                <MenuItem value={_?.value}>{_.label}</MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {/* <CustomInput
                              labelText="TFS(HOUR)"
                              id="tfs"
                              inputProps={{
                                value: tfs,
                                type: "number",
                                name: "tfs",
                                onChange: (e) => {
                                  setTfs(e.target.value);
                                },
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            /> */}
                      </GridItem>

                      <GridItem>
                        <FormControl
                          className="mt-3"
                          style={{ width: "150px" }}
                        >
                          <InputLabel className="label" id="filterBy">
                            Filter By
                          </InputLabel>
                          <Select
                            disabled={!admin}
                            labelId="filterBy"
                            value={filterBy}
                            name="Filter By"
                            onChange={handleForm}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            onChange={(e) => {
                              admin && setFilterBy(e.target.value);
                            }}
                          >
                            {colors.map((filter, index) => {
                              return (
                                <MenuItem value={filter}>{filter}</MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl className="mt-3" style={{ width: "70px" }}>
                          <InputLabel className="label" id="Strict">
                            Strict
                          </InputLabel>
                          <Select
                            disabled={!admin}
                            labelId="Strict"
                            value={strict}
                            name="Strict"
                            onChange={handleForm}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            onChange={(e) => {
                              admin && setStrict(e.target.value);
                            }}
                          >
                            {[
                              { label: "Yes", value: true },
                              { label: "No", value: false },
                            ].map((filter, index) => {
                              return (
                                <MenuItem value={filter.value}>
                                  {filter.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <Button disabled={!tfs} type="submit" color="primary">
                          + Add
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                </GridItem>
                <GridContainer className="mx-0" spacing={0}>
                  {scannerData?.tfs?.map((data, index) => {
                    return (
                      <GridItem className="mt-2 px-2" key={index}>
                        <Chip
                          label={
                            data?.tf +
                            " , " +
                            data.color +
                            " , " +
                            (data.strict ? "Yes" : "No")
                          }
                          onDelete={() => {
                            admin && deleteTfs(index);
                          }}
                        ></Chip>
                      </GridItem>
                    );
                  })}
                </GridContainer>

                <GridItem className="" xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem>
                      <CustomInput
                        labelText="CHART TF"
                        id="chart_tf"
                        inputProps={{
                          value: chartTf?.chartTf,
                          type: "number",
                          name: "chart_tf",
                          disabled: !admin,
                          onChange: (e) => {
                            admin &&
                              setChartTf({
                                ...chartTf,
                                chartTf: e.target.value,
                              });
                          },
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem>
                      <FormControl className="mt-4">
                        <InputLabel className="label" id="unit">
                          Unit
                        </InputLabel>
                        <Select
                          disabled={!admin}
                          id="unit"
                          name="unit"
                          value={chartTf?.unit}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          onChange={(e) => {
                            admin &&
                              setChartTf({
                                ...chartTf,
                                unit: e.target.value,
                              });
                          }}
                        >
                          {[
                            {
                              label: "Hours",
                              value: "h",
                            },
                            { label: "Minutes", value: "m" },
                          ].map((_, index) => {
                            return (
                              <MenuItem value={_?.value}>{_.label}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem className="" xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem>
                      <CustomInput
                        labelText="Days"
                        id="days"
                        inputProps={{
                          value: scannerData?.days,
                          type: "number",
                          name: "days",
                          disabled: !admin,
                          onChange: (e) => {
                            admin &&
                              setScannerData({
                                ...scannerData,
                                days: e.target.value,
                              });
                          },
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </LoaderWrapper>
          <CardFooter className="d-flex justify-content-end">
            <Button
              disabled={
                scannerData?.tfs?.length == 0 ||
                !parseInt(scannerData?.days) ||
                !parseInt(chartTf.chartTf)
              }
              color="primary"
              onClick={handleSubmit}
            >
              {loader ? (
                <div className="d-flex align-items-center">
                  <CircularProgress size={20} className="fill-white" />
                  <div className="ml-2">Preparing Heatmap...</div>
                </div>
              ) : (
                "Show heatmap"
              )}
            </Button>
          </CardFooter>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Scanner62;
