export const BASE_URL = "https://api.edgecharts.com";

// export const BASE_URL = "http://35.72.168.247";
//

// export const BASE_URL_SCANNER = "http://142.132.208.113";

export const AUTOSTRATEGY_URL = "https://xta-autostrategy.edgecharts.com";
export const BACKTESTER_URL = "https://xta-backtester.edgecharts.com";
export const COMMON_URL = "https://xta-common.edgecharts.com";
export const SCANNER_URL = "https://xta-scanner.edgecharts.com";
// export const TRADER_URL = BASE_URL_SCANNER + ":7410";

export const OSC = ["STOCH", "RSI", "FSR"];
export const CUSTOM_TYPE = ["Slope+Range", "Range", "Slope"];

export const PAIRS = [
  "UNFI/USDT", "TRB/USDT", "GALA/USDT", "JASMY/USDT", "APT/USDT", "RUNE/USDT", "ONE/USDT", "GMT/USDT", "OP/USDT", "FTM/USDT", "AVAX/USDT", "RVN/USDT", "ROSE/USDT", "FLOW/USDT", "BLZ/USDT", "IMX/USDT", "AR/USDT", "SUSHI/USDT", "MATIC/USDT", "FIL/USDT", "HOT/USDT", "ALPHA/USDT", "GRT/USDT", "THETA/USDT", "REEF/USDT", "ALGO/USDT", "FET/USDT", "LRC/USDT", "MANA/USDT", "SNX/USDT", "ENJ/USDT", "STG/USDT", "SPELL/USDT", "CRV/USDT", "KLAY/USDT", "OCEAN/USDT", "KSM/USDT", "SOL/USDT", "HBAR/USDT", "KNC/USDT", "APE/USDT", "HIGH/USDT", "DOGE/USDT", "FLM/USDT", "SXP/USDT", "OGN/USDT", "ANKR/USDT", "DENT/USDT", "BTC/USDT"
];

export const ScannerResult = {
  blue_band: {
    bgColor: "#0091EA",
    color: "#fff",
  },
  green: {
    bgColor: "#00BFA5",
    color: "#fff",
  },
  green_band: {
    bgColor: "#00BFA5",
    color: "#fff",
  },
  neutral_band: {
    color: "#fff",
    bgColor: "#3E2723",
  },
  orange_band: {
    bgColor: "#FF6D00",
    color: "#fff",
  },
  purple_band: {
    bgColor: "#6200EA",
    color: "#fff",
  },
  red: {
    bgColor: "#D50000",
    color: "#fff",
  },
  red_band: {
    bgColor: "#D50000",
    color: "#fff",
  },
  yellow_band: {
    bgColor: "#FFD600",
    color: "#000",
  },
  unavailable_pair: {
    bgColor: "#37474F",
    color: "#fff",
  },
};

export const colors = [
  "any",
  "blue",
  "green",
  "green or blue",
  "blue or empty",
  "red",
  "orange",
  "red or orange",
  "orange or empty",
  "empty",
  "green or blue or empty",
  "red or orange or empty",
  "blue or green or empty or orange",
];

export const colors_101 = [
  "any",
  "green",
  "red",
  "green or empty",
  "red or empty",
  "empty",
];

export const Global_62 = ["blue", "green", "orange", "red", "empty"];
export const Global_101 = ["green", "red", "empty"];

export const transition_62 = {
  green_transition: "any to green",
  red_transition: "any to red",
  white_transition: "any to empty",
};

export const transition_mtffsr = {
  green_transition: "any to green",
  red_transition: "any to red",
};

export const transition_101 = {
  green_transition: "any to green",
  red_transition: "any to red",
  white_transition: "any to empty",
};
