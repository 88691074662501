// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import logo from "assets/img/exwhere.svg";
import bgImage from "assets/img/sidebar-2.jpg";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import Button from "components/CustomButtons/Button.js";
import DialogHoc from "components/dialog/dialog";
import Sidebar from "components/Sidebar/Sidebar.js";
import Snackbar from "components/Snackbar/Snackbar";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";
import { getFirstPath } from "utils/helper";
import { getAuthData } from "utils/helper";
import { isAdmin } from "utils/helper";
import { Logout } from "utils/helper";

let ps;

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const SideBarLink = getAuthData().sideMenu || [];

  const switchRoutes = (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {routes.map((prop, key) => {
          const permission = SideBarLink?.filter(({ id }) => {
            return prop.id === id;
          });

          if (prop.layout === "/admin") {
            return isAdmin() || prop.auth == "user" ? (
              <Route
                path={prop.layout + prop.path}
                component={() => {
                  return (
                    <prop.component
                      permission={permission?.[0]?.permissions}
                    ></prop.component>
                  );
                }}
                key={key}
              />
            ) : (
              ""
            );
          }
          return null;
        })}

        <Redirect from="/admin" to={getFirstPath()} />
      </Switch>
    </Suspense>
  );
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Snackbar></Snackbar>
      <DialogHoc></DialogHoc>
      <Sidebar
        routes={routes}
        logoText={"Edgecharts"}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} id="sideSection" ref={mainPanel}>
        <div className="d-flex justify-content-end mr-3 mt-3">
          <Button
            onClick={Logout}
            color="primary"
            startIcon={<ExitToAppIcon />}
          >
            {" "}
            Logout
          </Button>
        </div>
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content} className="pt-0">
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
      </div>
    </div>
  );
}
