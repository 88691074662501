import { COMMON_URL } from "variables/config";
import {
  HEATMAP_101,
  HEATMAP_163,
  HEATMAP_35,
  HEATMAP_62,
  HEATMAP_COMP10,
  HEATMAP_V7,
} from "./endpoint";

const { post } = require("./service-methods");

export const get62ConditionHeatMap = (payload) => {
  return post(COMMON_URL + HEATMAP_62, payload);
};

export const get101ConditionHeatMap = (payload) => {
  return post(COMMON_URL + HEATMAP_101, payload);
};
export const get163ConditionHeatMap = (payload) => {
  return post(COMMON_URL + HEATMAP_163, payload);
};

export const getV7ConditionHeatMap = (payload) => {
  return post(COMMON_URL + HEATMAP_V7, payload);
};

export const get35ConditionHeatMap = (payload) => {
  return post(COMMON_URL + HEATMAP_35, payload);
};

export const getcomp10ConditionHeatMap = (payload) => {
  return post(COMMON_URL + HEATMAP_COMP10, payload);
};
