const {
  ALERT,
  SUB_ALERT,
  BULKSUBALERT,
  GET_SUB_ALERTS,
  GROUP,
  SUBALERT,
  STOP_STRATEGY,
  ORDER,
} = require("./endpoint");
const { get, deleteReq, patch, post } = require("./service-methods");

export const getAlertService = (id) => {
  let append = "";
  if (id) {
    append += `${id}/`;
  }
  return get(ALERT + append);
};

export const deleteAlertService = (id) => {
  return deleteReq(ALERT + id + "/");
};

export const updateAlertService = (id, data) => {
  return patch(ALERT + id + "/", data);
};

export const addAlertService = (data) => {
  return post(ALERT, data);
};

export const addGroups = (data, id) => {
  return post(GROUP + "/" + id + "/group/", data);
};

export const deleteGroups = (id, groupId) => {
  return deleteReq(GROUP + "/" + id + "/group/" + groupId + "/");
};

export const updateGroups = (data, id, groupId) => {
  return patch(GROUP + "/" + id + "/group/" + groupId + "/", data);
};

export const getSubAlertService = (id) => {
  return get(GET_SUB_ALERTS + "?id=" + id);
};

export const deleteSubAlertService = (id) => {
  return deleteReq(SUBALERT + id + "/");
};

export const updateSubAlertService = (id, data) => {
  return patch(SUB_ALERT + id + "/", data);
};

export const addSubAlertService = (data) => {
  return post(SUB_ALERT, data);
};

export const addUpdateBulkAlert = (data, id) => {
  return patch(BULKSUBALERT + id + "/", data);
};

export const stopStrategy = (id) => {
  return post(STOP_STRATEGY + id + "/");
};

export const Order = (id) => {
  return get(ORDER + id + "/");
};
